import { BaseUser, Role } from '@core/model/user.model';

export interface LoggedUser extends BaseUser {
  readonly fullName?: string;
  readonly role: LoggedUserRole;
}

export interface LoggedUserRole {
  name: Role;
  permissions: UserPermission[];
}

export interface UserPermission {
  readonly name: string;
}

export enum Permissions {
  // ADMIN
  canManageUsers = 'canManageUsers',
  canManageCompanies = 'canManageCompanies',
  canManagePayments = 'canManagePayments',
  canManageSubscriptions = 'canManageSubscriptions',
  canManageTickets = 'canManageTickets',
  canManageVouchers = 'canManageVouchers',
  canManageProducts = 'canManageProducts',
  canManageQuestionnaireFiles = 'canManageQuestionnaireFiles',
  canManageImprovementActionTemplates = 'canManageImprovementActionTemplates',
  canManageEmergenceTemplates = 'canManageEmergenceTemplates',
  canManageQuestionnaireMigrations = 'canManageQuestionnaireMigrations',
  canManageAffirmationFiles = 'canManageAffirmationFiles',
  canManageSlotTypes = 'canManageSlotTypes',
  canManageEnergyMeasuresTypes = 'canManageEnergyMeasuresTypes',
  canManageWorkflowYears = 'canManageWorkflowYears',
  canManageParameters = 'canManageParameters',
  canManageSelf = 'canManageSelf',
  canManageConfigData = 'canManageConfigData',
  canManageDocumentFolderTemplate = 'canManageDocumentFolderTemplate',

  // MAIN_USER
  canManageOwnUsers = 'canManageOwnUsers',
  canSubscribe = 'canSubscribe',
  canManageOwnCompany = 'canManageOwnCompany',
  canManageConfigurations = 'canManageConfigurations',
  canOpenTickets = 'canOpenTickets',
  hasPaidSubscription = 'hasPaidSubscription',

  // TUTOR
  canManageOwnCompanies = 'canManageOwnCompanies',
}
