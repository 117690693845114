import { Injectable } from '@angular/core';
import { Role, User } from '@core/model/user.model';
import { FlamingoHttpService } from '@flamingo/service/flamingo-http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { LoggedUser, Permissions } from '../model/logged-user.model';

@Injectable({
  providedIn: "root",
})
export class LoggedUserService {
  private _user$: BehaviorSubject<LoggedUser | null> =
    new BehaviorSubject<LoggedUser | null>(null);
  public user$: Observable<LoggedUser> = this._user$
    .asObservable()
    .pipe(filter((user: LoggedUser | null): user is LoggedUser => !!user));

  constructor(private flamingoHttpService: FlamingoHttpService) {}

  get user(): LoggedUser | null {
    return this._user$.value;
  }

  public fetch(): Observable<LoggedUser> {
    return this.flamingoHttpService.get<User>(`/me`).pipe(
      map(
        (user) =>
          ({
            ...user,
            role: { name: user.role, permissions: [] },
          }) as LoggedUser,
      ),
      // Set base permission
      map((user) => {
        const commonPermission = [
          { name: Permissions.canManageUsers },
          { name: Permissions.canManageCompanies },
          { name: Permissions.canManageOwnCompanies },
          { name: Permissions.canManagePayments },
          { name: Permissions.canManageSubscriptions },
          { name: Permissions.canManageTickets },
          { name: Permissions.canManageVouchers },
          { name: Permissions.canManageProducts },
          { name: Permissions.canManageQuestionnaireFiles },
          { name: Permissions.canManageQuestionnaireMigrations },
          { name: Permissions.canManageAffirmationFiles },
          { name: Permissions.canManageSlotTypes },
          { name: Permissions.canManageEnergyMeasuresTypes },
          { name: Permissions.canManageWorkflowYears },
          { name: Permissions.canManageTables },
          { name: Permissions.canManageParameters },
          { name: Permissions.canManageSelf },
          { name: Permissions.canManageConfigData },
          { name: Permissions.canManageDocumentFolderTemplate },
          { name: Permissions.canManageImprovementActionTemplates },
          { name: Permissions.canManageQuestionHelperTemplates },
          { name: Permissions.canManageEmergenceTemplates },
          { name: Permissions.canManageDesigner },
          { name: Permissions.canManageAffirmationGroup },
        ];
        switch (user.role.name) {
          case Role.ADMIN:
            user.role.permissions.push(...commonPermission, {
              name: Permissions.canManageQuestionnaireVersion,
            });
            break;
          case Role.TUTOR:
            user.role.permissions.push(...commonPermission);
            break;
          default:
            user.role.permissions.push(
              { name: Permissions.canManageCompanies },
              { name: Permissions.canManageSelf },
            );
            break;
        }
        return user;
      }),
      tap((user) => this._user$.next(user)),
    );
  }

  public clear(): void {
    this._user$.next(null);
  }

  can(permission: Permissions | Permissions[]): boolean {
    if (!Array.isArray(permission)) {
      permission = [permission];
    }
    return permission.every((p) =>
      this.user?.role?.permissions.map((v) => v.name).includes(p),
    );
  }

  canAtLeastOne(permission: Permissions | Permissions[]): boolean {
    if (!Array.isArray(permission)) {
      permission = [permission];
    }
    return permission.some((p) =>
      this.user?.role?.permissions.map((v) => v.name).includes(p),
    );
  }

  cannot(permission: Permissions | Permissions[]): boolean {
    if (!Array.isArray(permission)) {
      permission = [permission];
    }
    return !permission.every((p) =>
      this.user?.role?.permissions.map((v) => v.name).includes(p),
    );
  }
}
